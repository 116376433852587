import styled, { css } from "styled-components";

import { ButtonType } from "~/components/button";
import { HeroSubheadline } from "~/components/typography";
import { Grid } from "~/components/grid";

const CSS_CONFIG = css`
  --profile-width: 240px;
  --grid-margin: calc(
    ((100vw - min(100vw, 1440px)) / 2) + 7.5rem
  );
`;

export const DesignerGalleryWrapper = styled.div`
  ${CSS_CONFIG};

  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto 4rem;
`;

// export const DesignerLogo = styled.div`
//   display: block;
//   width: 100%;
//   height: 120px;
//   margin-top: 1rem;
//   margin-bottom: 1rem;
//   background-image: url(${({ $imagePath }) => $imagePath});
//   background-position: center;
//   background-size: cover;
//   border-radius: 8px;
// `;

export const DesignerLogo = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  // background-image: url(${({ $imagePath }) => $imagePath});
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: contain;
`;

export const DesignerHead = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${Grid} {
    // position: relative;
    // top: 50%;
    // transform: translateY(-50%);
  }
`;

export const DesignerProfile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 36%;
  height: 270px;
  max-width: 240px;
  margin-top: 1rem;
  padding: 2rem 4rem;
  padding-left: 0;
  text-align: right;
  filter:
    saturate(var(--saturate))
    blur(calc(var(--blur-ratio) * 10px));
  opacity: max(var(--saturate), 0.5);

  // &:before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   width: 8px;
  //   height: 100%;
  //   background-color: ${({ theme }) => theme.colors.primary};
  //   border-radius: 48px;
  // }

  ${HeroSubheadline} {
    display: block;
  }

  ${ButtonType} {
    pointer-events: auto;
  }
`;

export const DesignerCarousel = styled.ul`
  overflow-x: scroll;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  margin: 1rem 0;
  padding-right: var(--grid-margin);
  padding-left: var(--grid-margin);
  white-space: nowrap;
`;

export const DesignerCarouselItem = styled.li`
  display: inline-block;
  width: 30%;
  min-width: 260px;
  margin-right: 1rem;
  margin-bottom: 0;
  white-space: normal;

  &:first-child {
    margin-left: var(--profile-width);
  }
`;
