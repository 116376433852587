export const DESIGNERS = {
  "amanda-perna": {
    photo: "designers/photos/amanda-perna",
    bio: "<p>Amanda is a designer, content creator, speaker, author, illustrator, stylist, and entrepreneur. She has a mission to create joy and spread sunshine everywhere she goes. Her greeting cards on The Good Snail are the perfect way for all of us to do just that.</p>",
  },
  "big-house-card-co": {
    photo: "designers/photos/Bighouse_Card_Co._rkuauw",
    bio: "<p>Created in order to send greeting cards to those who have been incarcerated, Tanya of BigHouse Card Company was inspired through a personal experience. BigHouse Card Co. understands how much it means to send a card to someone who has lost touch with family and friends while living in isolation. Delivering cards that are heartfelt and tasteful, a card to someone who has been incarcerated can help the entire family’s mental and emotional health.</p>",
  },
  "brittany-paige": {
    photo: "designers/photos/Brittany_Paige_de5uw2",
    bio: "<p>Brittany is the illustrator + graphic designer behind this one-woman design studio. She began Brittany Paige in 2017 with just six greeting cards (all about break-ups). It has since evolved into the stationery and gift brand it is today, which aims to create witty and empowered goods for the unapologetic girl.</p>",
  },
  "cardrella-and-daville": {
    photo: null,
    bio: "<p>When Julie Reif isn’t running the whole The Good Snail show, she is creating greeting cards as Cardrella Daville, a brand that has the slightly gangster tasting cards you’re looking for. Trying to have some swag but don’t know how to show it? This is the perfect route of greeting cards to stroll down.</p>",
  },
  "carolyndraws": {
    photo: "designers/photos/carolyndraws_l6uv62",
    bio: "<p>Carolyn is the designer, hand-letterer and voice behind carolyndraws, a Toronto based paper goods company. She’s a hockey mom, avid book club member and eye roller who likes coffee as more than a friend. With the appropriate tagline, “cards for real people, inspired by real life;” carolyndraws creates cards that are laugh out loud relatable.</p>",
  },
  "cross-and-dot": {
    photo: "designers/photos/AlexanderCrossxDot-275_hh6lmr",
    bio: "<p>Based in Orange County CA, designer Alexandra Cao fell in love with lettering, watercolor, and illustration while trying to decompress after long days of teaching high school math. What started off as just a creative outlet, grew into a passion project focused on providing pretty paper goods that brighten up your desk and encouraging connections through snail mail.</p>",
  },
  "diamond-donatello": {
    photo: "designers/photos/Diamond_Donatello_t1usno",
    bio: "<p>Diamond Donatello brings meme culture to the greeting card for all occasions. A birthday visit from Kanye or an anniversary with Tina Fey and Amy Poehler. This Toronto based designer takes pop culture references and gives a unique and hilarious greeting card experience.</p>",
  },
  "fineasslines": {
    photo: "designers/photos/Fineasslines_n3q1uj",
    bio: "<p>FINEASSLINES is a Los Angeles based stationery company that has a knack for punny language and childish humor. Creator and designer, Sophia Elias creates greeting cards so beautiful, you’ll want to use them to redecorate your walls. Seriously.</p>",
  },
  "five-dot-post": {
    photo: "designers/photos/Five_Dot_Post_Better_Co._icrpsa",
    bio: "<p>When Jessica Walker isn’t helping others succeed in the greeting card industry, she is living out her dream of being a New York City designer creating funny greeting cards. She began designing greeting cards after her loved one’s cancer diagnosis and has shared her journey through her book, “Better Together: Navigating the Cancer Experience.” Her motto, “laughter is the best medicine” is what encouraged her to support the cancer community through many of her greeting cards and has now expanded into a large collection of cards that everyone can feel connected to.</p>",
  },
  "forward-card": null, // waiting
  "honest-af-cards": {
    photo: "designers/photos/Honest_AF_r2n8xc",
    bio: "<p>As the name suggests, it is time to be Honest AF with your greeting card needs. This Houston designer realized that greeting cards were filled with words and phrases some would rarely say. No need for flowery phrases and poetic nonsense when you can be completely honest with your feelings whether they are crass or even sometimes obscene.</p>",
  },
  "hu-is-hungry": {
    photo: "designers/photos/dingding-hu_qyaznj",
    bio: "<p>Dingding Hu is a New York-based illustrator, best known for her delightful digital illustration work, as well as hilarious characters. She has worked on projects for Google, MIT Media Lab, and DOT NYC. Her work has also appeared in HuffPost, the New York Times, and TED. Dingding shares her positivities by drawing food through her greeting card brand, Hu is Hungry.</p>",
  },
  "k-patricia-designs": {
    photo: "designers/photos/K.Patricia_Designs_chq4h0",
    bio: "<p>After doodling cards for her mother’s stationary store, Kelly found herself to be very high in demand for the simple, clean designs she was providing. This mother, daughter team now runs K. Patricia Designs producing sweet and snappy greeting cards for all occasions.</p>",
  },
  "lark-raven": null, // waiting
  "love-lemons-paperie": {
    photo: "designers/photos/Love_and_Lemons_Paperie_nitsk4",
    bio: "<p>When Ashley Martin’s wedding was postponed due to COVID19, she decided to take a disappointing situation and turn it into inspiration. Ashley created Love & Lemons Paperie to make relatable postponed wedding cards by a corona bride for corona brides. What started as a fun outlet, has since expanded with a focus on puns and pop culture. When she is not designing greeting cards, she is working as a construction engineer helping to rebuild Boston, Massachusetts.</p>",
  },
  "m-c-pressure": {
    photo: "designers/photos/MCpressure_w8jy06",
    bio: "<p>M.C Pressure is a letterpress print shop based out of the Nation's Oldest City, Saint Augustine, Florida. Founder, Ryan Tempro based his company name off, suprise surprise, a pun. M.C.Escher was always an inspiration to Ryan. So, combining the love of his work and pressure techniques involved with letterpress- M.C.Pressure was born! If he's not creating beautifully yet minimalist creations for greeting cards and wedding invitations, you will find him creating some of the finest pizzas.</p>",
  },
  "miki": {
    photo: "designers/photos/miki_dxs5bb",
    bio: "<p>Miki Twersky, designer and illustrator based out of New York City, has a minimalistic and clean approach while not taking herself too seriously. Providing a thoughtful and relatable outlook, she still finds a way to sneak in a laugh or two.</p>",
  },
  "nicole-marie-designs": {
    photo: "designers/photos/Nicole_Marie_Paperie_lfp4tf",
    bio: "<p>Nicole Alesi illustrates vibrant designs with a whimsical touch. She is a designer located in New York City that sees the need to be an environmentally-conscious creator; making everything by hand while keeping you warm and fuzzy with her messaging.</p>",
  },
  "paper-freckles": {
    photo: "designers/photos/PaperFreckles_kyaurh",
    bio: "<p>PaperFreckles is a Charlotte, North Carolina based greeting card line designed by Joe and Coleen Akers. Joe's unique lettering paired with humorous puns makes PaperFreckles a perfect choice for any pick-me-up card!</p>",
  },
  "r-is-for-robo": {
    photo: "designers/photos/R_Is_For_Robo_zzx0fl",
    bio: "<p>R is for Robo is a woman owned stationery business based in Atlanta, Georgia by Robyn Kozlowski. With a background in marketing and retail, as well as a passion for art and entrepreneurship, she stumbled into the world of greeting cards in early 2020. She quickly realized how rewarding it was to bring joy and humor to others during tough times. Through this experience, R is for Robo’s mission is to use paper goods to provide happiness and laughter during the good times, bad times, and everything in between.</p>",
  },
  "sam-jones": {
    photo: "designers/photos/Sam_Jones4_zjmfyr",
    bio: "<p>With razor sharp wit, Sam's brand of writing is real, raw and radical – certainly not the girl next door. Sam Jones currently holds the title of Creative Director across Viacomcbs’ Comedy Central, VH1, MTV, and TVland.</p><p>Brooklyn based, Sam conceptualizes satirical advertising ads that push boundaries and shift antiquated social norms. Sam is adding her humor to The Good Snail cards because she understands the importance of human connection and laughter.</p>",
  },
  "scotch-cream": {
    photo: "designers/photos/Scotch___Cream_2_orggno",
    bio: "<p>As the name suggests, Scotch & Cream is chic and elegant for the fancy in you. Designer Robin Soltis is based in Los Angeles where she is a greeting card triple threat - letterer, illustrator and designer. She describes her cards as having a vintage inspired aesthetic.</p>",
  },
  "simply-happy-cards": {
    photo: "designers/photos/megan-powell_yczvdx",
    bio: "<p>Since Megan was little, she's always loved making cards & stickers. In May 2020 she decided to take the leap and create Simply Happy Cards. Simply Happy Cards has one mission, to give people a meaningful way to celebrate life's small and big moments. While these moments may not always be positive ones, she believes that providing a medium to send a handwritten personal message has the power to brighten even the darkest of times and make the best moments even better.</p>"
  },
  "tiny": {
    photo: "designers/photos/tiny_fdqevo",
    bio: "<p>Daniel and Sara are the creative minds behind the brand, tiny. Their backgrounds have both been within design in the big city of Chicago for over 20 years. When COVID hit and they both lost their jobs, they decided to focus on smaller things and create their own greeting card and art print company. They created tiny. to take things back to basics and celebrate the smaller moments in life. Their cards represent a new push towards the personal and a more organic and slower approach towards design. Daniel and Sara love things that are cute, funny and nostalgic and hope their cards and prints can put more of that tiny goodness into the world.</p>",
  },
  "tiny-gang-designs": {
    photo: "designers/photos/Tiny_Gang_Designs_rfedct",
    bio: "<p>Katie and Steph, a duo with a punny outlook, show their love for humor through their greeting cards. Making cards that produce those belly laughs you’ll want to pass around the table. From elaborate to simple images, and everything in between.</p>",
  },
  "two-fat-cookies": {
    photo: "designers/photos/two-fat-cookies_geakxo",
    bio: "<p>Having grown up in the Bakery business with a family of Bakers & Chefs, Zoey has always had a passion for baking.  BUT, she also always had a passion for art & creating.  In the Two Fat Cookies Bake Shop located in Delray Beach, Florida, not only do they offer all the sweet treats, they offer fun Plates, Napkins, Candles & of course, All Occasion Cards from The Good Snail!  A light bulb went off and Zoey realized that maybe she could take her talents and apply them in a new way, creating her own custom cards for the Bake Shop!  Now you can celebrate not only with custom cakes, but a custom card designed by the Cake Artist herself!</p>",
  },
}
