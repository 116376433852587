import React from "react";
import HtmlToReact from "html-to-react";

import { getImageSrc } from "~/utils/cloudinary";
import { Grid } from "~/components/grid";
import { HeroSubheadline } from "~/components/typography";
import {
  MarketingBreak,
  MarketingCopy,
} from "~/components/marketing";
import { DESIGNERS } from "./designer-bio.constants";
import {
  DesignerBioWrapper,
  DesignerBioGreeting,
  DesignerBioPhoto,
} from "./designer-bio.style";

export const DesignerBio = ({ title, slug }) => {
  const HtmlToReactParser = new HtmlToReact.Parser();
  const designer = DESIGNERS[slug];

  if (!designer) {
    return null;
  }

  const photoProps = "c_fill,w_128,h_128,g_face";
  const imagePath = getImageSrc(designer.photo, photoProps);
  const bio = HtmlToReactParser.parse(designer.bio);

  return (
    <>
      <Grid>
        <DesignerBioGreeting>
          Meet the designer.
        </DesignerBioGreeting>
      </Grid>
      <MarketingBreak>
        <DesignerBioWrapper>
          {imagePath && <DesignerBioPhoto $imagePath={imagePath} />}
          <MarketingCopy as="div">
            <HeroSubheadline>{title}</HeroSubheadline>
            {bio}
          </MarketingCopy>
        </DesignerBioWrapper>
      </MarketingBreak>
    </>
  );
}
