import styled from "styled-components";

import { IS_SMALL } from "~/utils/media";
import { Grid } from "~/components/grid";
import { Subheadline } from "~/components/typography";

export const DesignerBioWrapper = styled(Grid)`
  display: flex;
  text-align: left;

  ${IS_SMALL} {
    flex-direction: column;
  }
`;

export const DesignerBioGreeting = styled(Subheadline)`
  margin-bottom: -6px;
  text-align: right;
  color: ${({ theme }) => theme.colors.accentTwo};

  ${IS_SMALL} {
    margin-bottom: -5px;
  }
`;

export const DesignerBioPhoto = styled.div`
  display: block;
  width: 100%;
  max-width: 128px;
  height: 128px;
  margin-right: 2rem;
  border-radius: 64px;
  background-image: url(${({ $imagePath }) => $imagePath});
  background-position: center;
  background-size: cover;

  ${IS_SMALL} {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;
