import React from "react";
import { graphql } from "gatsby";
import head from "lodash/head";

import { Main } from "~/layouts";
import { stripHTML } from "~/utils/string";
import { Seo } from "~/components/seo";
import { HeroTitle } from "~/components/hero";
import { DesignerBio, DESIGNERS } from "~/components/designers";
import { Collections } from "~/components/collections";
import { Footer } from "~/components/footer";

const DesignersPage = ({ data: { allShopifyProduct }, ...props }) => {
  const { products } = allShopifyProduct || {};
  const title = head(products)?.vendor || "";
  const slug = head(products)?.vendorSlug || "";
  const designer = DESIGNERS[slug];

  return (
    <Main fullView>
      <Seo
        title={title}
        description={stripHTML(designer?.bio || "")}
      />
      <HeroTitle {...{ title }} />
      <Collections {...{ products }} />
      <DesignerBio {...{ title, slug }} />
      <Footer />
    </Main>
  );
}

export const query = graphql`
  query($vendorSlug: String) {
    allShopifyProduct(
      filter: {
        status: { eq: "ACTIVE" },
        vendorSlug: { eq: $vendorSlug },
      },
    ) {
      products: nodes {
        id
        shopifyId
        slug: handle
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        variants {
          id
          shopifyId
          price
          availableForSale
          displayName
          sku
          title
        }
        images {
          id
          altText
          originalSrc
          src
          width
          height
        }
        title
        description
        descriptionHtml
        productType
        tags
        status
        vendor
        vendorSlug
      }
    }
  }
`

export default DesignersPage;
